import React, { createContext, useContext, useEffect, useReducer } from "react";
import axios from "../utils/axios";
import { failure, success } from "../utils/toast";
import { getHeaders } from "../utils";

// Initial state
const initialState = {
  loading: false,
  data: [],
  // pagination: null,
  errors: {},
  addEvent: {
    loading: false,
    errors: {},
  },
  oneEvent: {
    loading: false,
    data: [],
    errors: "",
  },
};

const actionTypes = {
  ADD_EVENT_LOADING: "ADD_EVENT_LOADING",
  ADD_EVENT_FAILURE: "ADD_EVENT_FAILURE",
  EVENTS_LOADING: "EVENTS_LOADING",
  EVENTS_SUCCESS: "EVENTS_SUCCESS",
  EVENTS_FAILURE: "EVENTS_FAILURE",
  ONE_EVENT_LOADING: "ONE_EVENT_LOADING",
  ONE_EVENT_SUCCESS: "ONE_EVENT_SUCCESS",
  ONE_EVENT_FAILURE: "ONE_EVENT_FAILURE",
};

const eventReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.ADD_EVENT_LOADING:
      return {
        ...state,
        addEvent: {
          loading: action.payload,
        },
      };
    case actionTypes.ADD_EVENT_FAILURE:
      return {
        ...state,
        addEvent: {
          loading: false,
          data: {},
          errors: action.payload,
        },
      };

    case actionTypes.EVENTS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case actionTypes.EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        // pagination: action.payload.pagination,
        errors: "",
      };
    case actionTypes.EVENTS_FAILURE:
      return {
        ...state,
        loading: false,
        // pagination: null,
        errors: action.payload,
      };
    case actionTypes.ONE_EVENT_LOADING:
      return {
        ...state,
        oneEvent: { loading: action.payload },
      };
    case actionTypes.ONE_EVENT_SUCCESS:
      return {
        ...state,
        oneEvent: { loading: false, data: action.payload, errors: "" },
      };
    case actionTypes.ONE_EVENT_FAILURE:
      return {
        ...state,
        oneEvent: { loading: false, errors: action.payload },
      };
    default:
      return state;
  }
};

// Create the context
const EventContext = createContext();

// Create a provider component
const EventProvider = ({ children }) => {
  // Function to add an event to the context
  const [state, dispatch] = useReducer(eventReducer, initialState);

  useEffect(() => {
    fetchAllEvents();
  }, []);

  const fetchAllEvents = (data = {}) => {
    dispatch({
      type: actionTypes.EVENTS_LOADING,
      payload: true,
    });
    axios
      .get("/event/events/", data)
      .then((res) => {
        dispatch({
          type: actionTypes.EVENTS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        failure();
        dispatch({
          type: actionTypes.EVENTS_FAILURE,
          payload: err?.response?.data?.message
            ? err.response.data.message
            : "",
        });
      });
  };

  const searchEvents = (data = {}) => {
    dispatch({
      type: actionTypes.EVENTS_LOADING,
      payload: true,
    });
    axios
      .post("/event/search/", data, getHeaders())
      .then((res) => {
        dispatch({
          type: actionTypes.EVENTS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        failure();
        dispatch({
          type: actionTypes.EVENTS_FAILURE,
          payload: err?.response?.data?.message
            ? err.response.data.message
            : "",
        });
      });
  };

  const addEvent = (newEvent, cb) => {
    dispatch({
      type: actionTypes.ADD_EVENT_LOADING,
      payload: true,
    });
    axios
      .post("/event/add/", newEvent, getHeaders())
      .then((res) => {
        dispatch({
          type: actionTypes.ADD_EVENT_LOADING,
          payload: false,
        });
        success(res.data.message);
        cb();
      })
      .catch((err) => {
        failure(err?.response?.data?.message);
        dispatch({
          type: actionTypes.ADD_EVENT_FAILURE,
          payload: err?.response?.data?.errors ? err.response.data.errors : {},
        });
      });
  };

  const fetchOneEvent = (id) => {
    dispatch({
      type: actionTypes.ONE_EVENT_LOADING,
      payload: true,
    });
    axios
      .get(`/event/events/${id}/`)
      .then((res) => {
        dispatch({
          type: actionTypes.ONE_EVENT_SUCCESS,
          payload: res?.data?.data,
        });
      })
      .catch((err) => {
        failure();
        dispatch({
          type: actionTypes.ONE_EVENT_FAILURE,
          payload: err?.response?.data?.message
            ? err.response.data.message
            : "",
        });
      });
  };

  // Value object to be provided to consuming components
  const contextValue = {
    state,
    addEvent,
    fetchAllEvents,
    searchEvents,
    fetchOneEvent,
  };

  return (
    <EventContext.Provider value={contextValue}>
      {children}
    </EventContext.Provider>
  );
};

// Create a custom hook to consume the event context
const useEventContext = () => {
  const context = useContext(EventContext);
  if (!context) {
    failure("No context");
    throw new Error("useEventContext must be used within an EventProvider");
  }
  return context;
};

export { EventProvider, useEventContext };
